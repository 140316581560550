import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SectionAboutComponent } from './components/section-about/section-about.component';
import { SectionWashComponent } from './components/section-wash/section-wash.component';
import { SectionCleanprepComponent } from './components/section-cleanprep/section-cleanprep.component';
import { SectionPolishComponent } from './components/section-polish/section-polish.component';
import { SectionWaxprotectComponent } from './components/section-waxprotect/section-waxprotect.component';
import { SectionMaintainComponent } from './components/section-maintain/section-maintain.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    SectionAboutComponent,
    SectionWashComponent,
    SectionCleanprepComponent,
    SectionPolishComponent,
    SectionWaxprotectComponent,
    SectionMaintainComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

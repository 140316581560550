import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-cleanprep',
  templateUrl: './section-cleanprep.component.html',
  styleUrls: ['./section-cleanprep.component.css']
})
export class SectionCleanprepComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-waxprotect',
  templateUrl: './section-waxprotect.component.html',
  styleUrls: ['./section-waxprotect.component.css']
})
export class SectionWaxprotectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
